import { useEffect, useState } from "react";
import { ParticleAuthModule, ParticleProvider } from "@biconomy/particle-auth";
import {
  DEFAULT_ECDSA_OWNERSHIP_MODULE,
  DEFAULT_ENTRYPOINT_ADDRESS,
  ECDSAOwnershipValidationModule,
} from "@biconomy/modules";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import { ChainId } from "@biconomy/core-types";
import { BiconomyPaymaster, IPaymaster } from "@biconomy/paymaster";
import { ethers } from "ethers";
import { Bundler, IBundler } from "@biconomy/bundler";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../store/auth/authSlice";
const Biconomy = () => {
  const [smartAccount, setSmartAccount] = useState<any>(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [email, setEmail] = useState<string | undefined>("");
  const accessToken = useSelector<any>((state) => state.auth.accessToken);
  const dispacth = useDispatch();
  // const [userInfo, setUserInfo] = useState<any>();
  const AuthToBack = async (uuid: any, token: any, publicKey: any) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_MASTERPIEC_API}/login/provider:particle`,
        method: "post",
        headers: {
          "x-domain-name": process.env.REACT_APP_DOMAIN,
        },
        data: {
          uuid,
          token,
          publicKey,
        },
      };
      const response = await axios(config);

      dispacth(login(response.data));
      // window.location.replace("/");
    } catch (error) {
      //
      await particle.auth.logout();
      dispacth(logout());
    }
  };

  const bundler: IBundler = new Bundler({
    bundlerUrl:
      "https://bundler.biconomy.io/api/v2/137/hj789s.jk780Yh-iJkl-Ghjo-af80-68ggf74bbH67",
    chainId: ChainId.POLYGON_MAINNET, // or any supported chain of your choice
    entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
  });

  const paymaster: IPaymaster = new BiconomyPaymaster({
    paymasterUrl: `https://paymaster.biconomy.io/api/v1/137/cVlpUHxvU.415fc81b-db03-4a79-b6b5-4df764161932`,
  });

  const particle = new ParticleAuthModule.ParticleNetwork({
    projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID,
    clientKey: process.env.REACT_APP_PARTICLE_CLIENT_KEY,
    appId: process.env.REACT_APP_PARTICLE_APP_ID,
    wallet: {
      displayWalletEntry: false,
      defaultWalletEntryPosition: ParticleAuthModule.WalletEntryPosition.BR,
      uiMode: "dark",
    },
  });

  const createWallet = async () => {
    const particleProvider = new ParticleProvider(particle.auth);
    const web3Provider = new ethers.providers.Web3Provider(
      particleProvider,
      "any"
    );

    const module = await ECDSAOwnershipValidationModule.create({
      signer: web3Provider.getSigner(),
      moduleAddress: DEFAULT_ECDSA_OWNERSHIP_MODULE,
    });

    let biconomySmartAccount = await BiconomySmartAccountV2.create({
      chainId: ChainId.POLYGON_MAINNET,
      bundler: bundler,
      paymaster: paymaster,
      entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
      defaultValidationModule: module,
      activeValidationModule: module,
    });

    const address = await biconomySmartAccount.getAccountAddress();
    setWalletAddress(address);
    setSmartAccount(biconomySmartAccount);
  };
  const connect = async () => {
    try {
      let isLoggedIn = particle.auth.isLogin();
      if (isLoggedIn) {
        createWallet();
        return;
      }
      const userInfo = await particle.auth.login({
        supportAuthTypes: "mail",
      });
      // setUserInfo(userInfo);
      //
      setEmail(userInfo.email);

      const particleProvider = new ParticleProvider(particle.auth);
      const web3Provider = new ethers.providers.Web3Provider(
        particleProvider,
        "any"
      );

      const module = await ECDSAOwnershipValidationModule.create({
        signer: web3Provider.getSigner(),
        moduleAddress: DEFAULT_ECDSA_OWNERSHIP_MODULE,
      });

      let biconomySmartAccount: any = await BiconomySmartAccountV2.create({
        chainId: ChainId.POLYGON_MAINNET,
        bundler: bundler,
        paymaster: paymaster,
        entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
        defaultValidationModule: module,
        activeValidationModule: module,
      });
      const address = await biconomySmartAccount.getAccountAddress();
      setWalletAddress(address);
      setSmartAccount(biconomySmartAccount);
      if (!accessToken) {
        AuthToBack(userInfo?.uuid, userInfo?.token, address);
      }
    } catch (error) {
      //
      await particle.auth.logout();
      dispacth(logout());
    }
  };

  useEffect(() => {
    if (particle.auth.isLogin()) {
      connect();
    }
  }, [particle.auth.isLogin()]);

  return { connect, particle, smartAccount, walletAddress };
};

export default Biconomy;
